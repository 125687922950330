import React from "react";
import { Field } from "react-final-form"; //FormSpy

import { Form, FormGroup, Label, Button } from "reactstrap";

import { FormFeedbackAdapter, InputAdapter } from "./ffadapters";
import { SubmitErrorAlerts, ValidationErrorAlert } from "./ffhelpers";

import { validateRequired } from "../lib/validators";

const PostForm = ({
  formID,
  handleSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  optionsFoods,
  meta,
  brand,
  reset,
  pristine,
  submitError,
  submitFailed,
  submitting,
  values,
  ...rest
}) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup>
      <Label htmlFor='why'>
        Why did you select {brand}? (the name, colour, font etc.):
      </Label>
      <Field
        name='why'
        component={InputAdapter}
        validate={validateRequired()}
        id='why'
        type='textarea'
      />
      <FormFeedbackAdapter name='why' />
    </FormGroup>
    <FormGroup>
      <Label htmlFor='influence'>
        What else would influence your decision to buy from {brand}:
      </Label>
      <Field
        name='influence'
        component={InputAdapter}
        validate={validateRequired()}
        id='influence'
        type='textarea'
      />
      <FormFeedbackAdapter name='influence' />
    </FormGroup>
    <hr className='my-4' />
    <ValidationErrorAlert
      hasValidationErrors={hasValidationErrors}
      submitFailed={submitFailed}
    />
    <SubmitErrorAlerts submitError={submitError} />
    <div className='text-center'>
      <Button
        type='submit'
        size='lg'
        color='success'
        disabled={submitting}
        className='px-5 w-100'
      >
        Submit
      </Button>
    </div>
    {/*values ? (
      <pre>{JSON.stringify(values, 0, 2)}</pre>
    ) : (
      <FormSpy subscription={{ values: true }}>
        {({ values }) => (
          <pre className='border mt-3 mb-0 p-3'>
            {JSON.stringify(values, 0, 2)}
          </pre>
        )}
      </FormSpy>
        )*/}
  </Form>
);

export default PostForm;
