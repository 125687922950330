import React from "react";
import { Form } from "react-final-form";
import DemographicsForm from "./DemographicsForm";

const FormContainer = ({ formSuccess }) => {
  const newSubmit = values => {
    formSuccess({ ...values, page: 1 });
  };

  return (
    <Form
      onSubmit={newSubmit}
      subscription={{
        hasSubmitErrors: true,
        hasValidationErrors: true,
        pristine: true,
        submitError: true,
        submitFailed: true,
        submitting: true
      }}
      component={DemographicsForm}
    />
  );
};

export default FormContainer;
