import React from "react";
import { Field } from "react-final-form";

import { Button, Form, FormGroup, Label } from "reactstrap";

import {
  FormFeedbackAdapter,
  SelectAdapter,
  ToggleAdapter
} from "./ffadapters";
import { SubmitErrorAlerts, ValidationErrorAlert } from "./ffhelpers";

import { validateRequired } from "../lib/validators";

import genderOptions from "../data/gender";
import ageOptions from "../data/age";

const DemographicsForm = ({
  formID,
  handleSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  optionsFoods,
  meta,
  reset,
  pristine,
  submitError,
  submitFailed,
  submitting,
  values,
  ...rest
}) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup>
      <Label htmlFor={`gender`}>Gender:</Label>
      <Field
        name='gender'
        component={SelectAdapter}
        validate={validateRequired()}
        type='select'
        id={`gender`}
        placeholder='Gender'
        options={genderOptions}
      />
      <FormFeedbackAdapter name='gender' />
    </FormGroup>
    <FormGroup>
      <Label htmlFor={`age`}>Age:</Label>
      <Field
        name='age'
        component={SelectAdapter}
        validate={validateRequired()}
        type='select'
        id={`age`}
        placeholder='Age'
        options={ageOptions}
      />
      <FormFeedbackAdapter name='age' />
    </FormGroup>
    <FormGroup>
      <Label htmlFor={`homeowner`}>Homeowner:</Label>
      <Field
        name='homeowner'
        component={ToggleAdapter}
        validate={validateRequired()}
        id={`homeowner`}
      />
      <FormFeedbackAdapter name='homeowner' />
    </FormGroup>
    <hr className='my-4' />
    <ValidationErrorAlert
      hasValidationErrors={hasValidationErrors}
      submitFailed={submitFailed}
    />
    <SubmitErrorAlerts submitError={submitError} />
    <div className='text-center'>
      <Button
        type='submit'
        size='lg'
        color='success'
        disabled={submitting}
        className='px-5 w-100'
      >
        Submit
      </Button>
    </div>
    {/*values ? (
      <pre>{JSON.stringify(values, 0, 2)}</pre>
    ) : (
      <FormSpy subscription={{ values: true }}>
        {({ values }) => (
          <pre className='border mt-3 mb-0 p-3'>
            {JSON.stringify(values, 0, 2)}
          </pre>
        )}
      </FormSpy>
        )*/}
  </Form>
);

export default DemographicsForm;
