const age = {
  1624: "16-24",
  2534: "25-34",
  3544: "35-44",
  4554: "45-54",
  5564: "55-64",
  6574: "65-74",
  7500: "75+"
};
export default Object.keys(age).map(value => ({
  value,
  label: age[value]
}));
