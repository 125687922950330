import React, { useState, useEffect } from "react";
import { Col, Container, Card, CardBody, CardHeader, Row } from "reactstrap";
import axios from "axios";
import Demographics from "./components/DemographicsWrapper";
import CTM from "./components/CTMWrapper";
import Post from "./components/PostWrapper";

import { ReactComponent as Tick } from "./components/tick.svg";
import { ReactComponent as Loader } from "./components/loader.svg";

const App = () => {
  const [tickanimation, setTickanimation] = useState(false);
  const [data, setData] = useState({
    page: 0
  });

  useEffect(() => {
    document.title = "Brand selection";
  }, []);

  const optionImg = brand => {
    // replace this will url for just the logos
    const urls = {
      now4cover: "./static/logos/now4cover.png",
      Diffo: "./static/logos/diffo.png",
      Frontier: "./static/logos/frontier.png",
      Luxby: "./static/logos/luxby.png"
    };
    return urls[brand];
  };

  const nextPage = values => {
    if (values.page === 3) {
      values.useragent = navigator.userAgent;
      setData({ ...data, ...values }); // updates the page
      setTimeout(() => {
        axios
          .post("/post.php", { ...data, ...values })
          .then(function(response) {
            values.success = true;
            setData({ ...data, ...values }); // shows success
            setTimeout(() => {
              setTickanimation("drawn");
            }, 250);
          })
          .catch(function(error) {
            // Its failed but lets display like its ok
            values.success = true;
            setData({ ...data, ...values });
            setTimeout(() => {
              setTickanimation("drawn");
            }, 250);
          });
      }, 2000);
    } else {
      setData({ ...data, ...values });
    }
  };
  return (
    <Container className='app'>
      <Row>
        <Col
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
          className='d-flex vmh-100 align-items-center justify-content-center'
        >
          {data.page === 0 ? (
            <Card className='Demographics'>
              <CardHeader tag='h5' className='text-center'>
                To help our analysis, please answer these questions.
              </CardHeader>
              <CardBody>
                <Demographics formSuccess={nextPage} />
              </CardBody>
            </Card>
          ) : data.page === 1 ? (
            <Card className='CTM'>
              <CTM formSuccess={nextPage} />
            </Card>
          ) : data.page === 2 ? (
            <Card className='Post'>
              <CardHeader tag='h5' className='text-center'>
                <img
                  className='selected-logo mb-2'
                  src={optionImg(data.brand)}
                  alt='selected-logo'
                />
                <br />
                Please let us know the reason behind your choice
              </CardHeader>
              <CardBody>
                <Post formSuccess={nextPage} brand={data.brand} />
              </CardBody>
            </Card>
          ) : data.page === 3 && data.success ? (
            <Card className='Complete'>
              <CardBody>
                <div className='success mb-4'>
                  <div className={`trigger ${tickanimation}`}></div>
                  <Tick />
                </div>
                <h5 className='text-center'>
                  Your answers have been recorded.
                  <br />
                  Thank you for your time and input in our brand selection.
                </h5>
              </CardBody>
            </Card>
          ) : (
            <Card className='Submitting'>
              <CardBody>
                <div className='success mb-4'>
                  <Loader />
                </div>
                <h5 className='text-center'>Submitting your answers...</h5>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default App;
