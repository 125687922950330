import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

// import "./Toggle.css";

const ToggleAdapter = ({
  input,
  meta,
  invalid = meta => meta.touched && meta.invalid,
  valid = () => false,
  ...rest
}) => {
  const handleClickTrue = e => {
    e.target.value = true;
    input.onChange(e);
  };
  const handleClickFalse = e => {
    e.target.value = false;
    input.onChange(e);
  };
  return (
    <ButtonGroup
      className={`w-100 form-control p-0 ${
        invalid(meta) ? "is-invalid" : ""
      }  ${valid(meta) ? "is-valid" : ""}`}
    >
      <Button
        variant="outline-secondary"
        className={`${input.value === "true" ? "active" : ""}`}
        onClick={handleClickTrue}
      >
        Yes
      </Button>
      <Button
        variant="outline-secondary"
        className={`${input.value === "false" ? "active" : ""}`}
        onClick={handleClickFalse}
      >
        No
      </Button>
    </ButtonGroup>
  );
};

export default ToggleAdapter;
