import React, { useState } from "react";
import { Button, Modal, CardText, ModalBody, ModalFooter } from "reactstrap";

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const CTMContainer = ({ formSuccess }) => {
  const [modal, setModal] = useState(true);
  const [options] = useState(
    shuffle(["now4cover", "Diffo", "Frontier", "Luxby"])
  );

  const toggle = () => setModal(!modal);

  const optionImg = brand => {
    const urls = {
      now4cover: "./static/logos/now4cover.png",
      Diffo: "./static/logos/diffo.png",
      Frontier: "./static/logos/frontier.png",
      Luxby: "./static/logos/luxby.png"
    };
    return urls[brand];
  };

  return (
    <div className='ctm-wrapper'>
      <div
        className='result result-one'
        onClick={() => {
          formSuccess({
            page: 2,
            brand: options[0],
            position: 1,
            positions: JSON.stringify(options)
          });
        }}
      >
        <div className='row'>
          <div class='provider col'>
            <img
              src={optionImg(options[0])}
              alt='option one'
              className='provider-logo'
            />
          </div>
          <div className='annual-payment col'>
            <span>&pound;194</span>
            <sup>.02</sup>
          </div>
          <div className='more-details col'>
            <div className='btn'>SELECT</div>
          </div>
        </div>
      </div>
      <div
        className='result result-two'
        onClick={() => {
          formSuccess({
            page: 2,
            brand: options[1],
            position: 2,
            positions: JSON.stringify(options)
          });
        }}
      >
        <div className='row'>
          <div class='provider col'>
            <img
              src={optionImg(options[1])}
              alt='option one'
              className='provider-logo'
            />
          </div>
          <div className='annual-payment col'>
            <span>&pound;194</span>
            <sup>.02</sup>
          </div>
          <div className='more-details col'>
            <div className='btn'>SELECT</div>
          </div>
        </div>
      </div>
      <div
        className='result result-three'
        onClick={() => {
          formSuccess({
            page: 2,
            brand: options[2],
            position: 3,
            positions: JSON.stringify(options)
          });
        }}
      >
        <div className='row'>
          <div class='provider col'>
            <img
              src={optionImg(options[2])}
              alt='option one'
              className='provider-logo'
            />
          </div>
          <div className='annual-payment col'>
            <span>&pound;194</span>
            <sup>.02</sup>
          </div>
          <div className='more-details col'>
            <div className='btn'>SELECT</div>
          </div>
        </div>
      </div>
      <div
        className='result result-four'
        onClick={() => {
          formSuccess({
            page: 2,
            brand: options[3],
            position: 4,
            positions: JSON.stringify(options)
          });
        }}
      >
        <div className='row'>
          <div class='provider col'>
            <img
              src={optionImg(options[3])}
              alt='option one'
              className='provider-logo'
            />
          </div>
          <div className='annual-payment col'>
            <span>&pound;194</span>
            <sup>.02</sup>
          </div>
          <div className='more-details col'>
            <div className='btn'>SELECT</div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalBody className='text-center px-4'>
          <p>
            You have just quoted for home insurance on a comparision website.
          </p>
          <CardText tag='h5'>
            Select which option you are most likely to choose.
          </CardText>
        </ModalBody>
        <ModalFooter>
          <Button size='lg' color='success' onClick={toggle} className='w-100'>
            Next
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CTMContainer;
